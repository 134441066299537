import { useEffect, useState } from "react";
import { menuData } from "../../../data/menu.data";
import { Title } from "../../Title/Title";
import { MenuGroups } from "../MenuGroups/MenuGroups";
import { MenuLinks } from "../MenuLinks/MenuLinks";
import "./MenuList.css";

export const MenuList = () => {
  const [activeSection, setActiveSection] = useState(menuData[0].group);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      menuData.forEach((section) => {
        const element = document.getElementById(section.group);
        if (element) {
          const rect = element.getBoundingClientRect();
          const elementPosition = rect.top + window.scrollY;

          if (
            scrollPosition >= elementPosition &&
            scrollPosition < elementPosition + element.offsetHeight
          ) {
            setActiveSection(section.group);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="menu-list">
      <div className="menu-list-top">
        <div className="content">
          <Title styles="h1-menu" title="Меню кофейни в ТЦ Авентура" />
        </div>
        <MenuLinks items={menuData} activeItem={activeSection} />
      </div>
      <div className="menu-list-wrap content">
        {menuData &&
          menuData.map((item) => (
            <MenuGroups
              key={item.group}
              name={item.name}
              group={item.group}
              items={item.items}
            />
          ))}
      </div>
    </section>
  );
};
