import "./MenuItem.css";
import { MenuItemProps } from "../types/item.type";

export const MenuItem = ({
  title,
  description,
  price,
  photo,
}: MenuItemProps) => {
  return (
    <div className="menu-item">
      <div className="menu-item-photo">
        <img src={photo} alt={title} />
      </div>
      <div className="menu-item-info">
        <div className="menu-item-title">
          <p>{title}</p>
          <p className="menu-item-price">{price} ₽</p>
        </div>
        <p className="menu-item-description">{description}</p>
      </div>
    </div>
  );
};
