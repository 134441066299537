import "./Logo.css";
import whiteLogo from "../../assets/images/logo_white.png";

export const Logo = () => {
  return (
    <div className="logo">
      <img src={whiteLogo} alt="Logo white" />
    </div>
  );
};
