import { Logo } from "../Logo/Logo";
import "./Header.css";

export const Header = () => {
  return (
    <header className="header">
      <div className="content">
        <Logo />
      </div>
    </header>
  );
};
