import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { BasicLayout } from "./layouts/BasicLayout";
import { MenuChertanovo } from "./pages/MenuChertanovo";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="/chertanovo/menu" />,
        },
        {
          path: "chertanovo/menu",
          element: <MenuChertanovo />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/chertanovo/menu" />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
