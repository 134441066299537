import { MenuItem } from "../MenuItem/MenuItem";
import { MenuGroupProps } from "../types/item.type";
import "./MenuGroups.css";

export const MenuGroups = ({ group, name, items }: MenuGroupProps) => {
  return (
    <div id={group} className="menu-group-wrap">
      <div id={`${group}-start`} className="menu-group-anchor"></div>
      <p className="menu-group-title">
        {name}
      </p>
      <div className="menu-group">
        {items &&
          items.map((item, index) => (
            <MenuItem
              key={index}
              title={item.title}
              description={item.description}
              price={item.price}
              photo={item.photo}
            />
          ))}
      </div>
    </div>
  );
};
